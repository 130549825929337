import {
  ChevronDownIcon,
  CollapsibleContent,
  CollapsibleRoot,
  CollapsibleTrigger,
} from '@mr-yum/frontend-ui'
import cn from 'classnames'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  postcodes: string[]
}

export const AddressPostcodes = ({ postcodes }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <CollapsibleRoot open={open} onOpenChange={setOpen}>
      <div className="mb-4 flex items-center justify-end text-foreground">
        <CollapsibleTrigger asChild>
          <div className="flex items-center py-1 text-foreground my-body-sm hover:underline">
            {open ? (
              <>
                <FormattedMessage
                  defaultMessage="Hide delivery areas"
                  id="H6g8U2"
                />
              </>
            ) : (
              <>
                <FormattedMessage
                  defaultMessage="Show delivery areas"
                  id="nvYHVs"
                />
              </>
            )}
            <ChevronDownIcon
              className={cn(
                'relative top-[1px] ml-1 transition-all duration-300 ease-in-out',
                { 'rotate-180': open },
              )}
            />
          </div>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent>
        <div className="mb-10 text-center my-body-sm">
          <FormattedMessage defaultMessage="Delivering to" id="uwvXj+" />{' '}
          {postcodes.join(', ')}
        </div>
      </CollapsibleContent>
    </CollapsibleRoot>
  )
}
