import { useScript } from '@mr-yum/frontend-core/dist/hooks/useScript'
import {
  ComboboxContent,
  ComboboxInput,
  ComboboxInputValueChangeDetails,
  ComboboxItem,
  ComboboxLabel,
  ComboboxRoot,
  ComboboxValueChangeDetails,
  createListCollection,
  FieldMessage,
  FormControl,
  FormControlProps,
} from '@mr-yum/frontend-ui'
import { config } from 'lib/config'
import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { useController } from 'react-hook-form'
import usePlacesAutocomplete from 'use-places-autocomplete'

interface Props {
  autoFocus?: boolean
  name: string
  placeholder: string
  lat?: number
  lng?: number
  radius?: number
  caption?: string
}

export type AddressFieldProps = Omit<
  Pick<FormControlProps, 'label'>,
  'options' | 'onSelect'
> &
  Props

const script = `https://maps.googleapis.com/maps/api/js?key=${config.googlePlacesApiKey}&libraries=places&callback=initMap`

export const AddressField = ({
  autoFocus,
  name,
  placeholder,
  label,
  lat,
  lng,
  radius,
  caption,
}: PropsWithChildren<AddressFieldProps>) => {
  const [loaded] = useScript(script)

  const { field, fieldState } = useController({ name })
  const googlePlaceIdController = useController({ name: 'googlePlaceId' })

  const radiusDefault = lat && lng ? 150_000 : 1_000_000

  const { suggestions, value, setValue } = usePlacesAutocomplete({
    requestOptions: {
      location: loaded
        ? new window.google.maps.LatLng(lat || -25, lng || 134)
        : undefined,
      radius: radius || radiusDefault,
    },
    debounce: 300,
    callbackName: 'initMap',
  })

  const handleValueChange = (details: ComboboxValueChangeDetails) => {
    field.onChange(details.value)

    if (details.items[0]?.googlePlaceId) {
      googlePlaceIdController.field.onChange(details.items[0].googlePlaceId)
    } else {
      googlePlaceIdController.field.onChange('')
    }
  }

  const handleInputValueChange = (details: ComboboxInputValueChangeDetails) => {
    setValue(details.inputValue)
  }

  const collection = useMemo(
    () =>
      createListCollection({
        items:
          suggestions.data?.map((result) => ({
            value: result.description,
            googlePlaceId: result.place_id,
          })) ?? [],
      }),
    [suggestions.data],
  )

  useEffect(() => {
    if (!value && field.value.length) {
      setValue(field.value[0])
    }
  }, [value, field.value, setValue])

  return (
    <FormControl
      label={label}
      caption={caption}
      feedback={
        !!fieldState.error && (
          <FieldMessage type="critical">
            {fieldState.error.message}
          </FieldMessage>
        )
      }
      htmlFor={name}
    >
      <ComboboxRoot
        size="lg"
        variant="subtle"
        autoFocus={autoFocus}
        value={field.value}
        inputValue={value}
        collection={collection}
        placeholder={placeholder}
        onValueChange={handleValueChange}
        onInputValueChange={handleInputValueChange}
      >
        <ComboboxLabel className="hidden">{label}</ComboboxLabel>
        <ComboboxInput />
        <ComboboxContent className="z-10" portalled={false}>
          {collection.items.map((item) => (
            <ComboboxItem item={item} key={item.value}>
              {item.value}
            </ComboboxItem>
          ))}
        </ComboboxContent>
      </ComboboxRoot>
    </FormControl>
  )
}
